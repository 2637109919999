export const seo = {
  url: 'firma/wspieramy/aleksandra-wasiewicz',
  title: {
    pl: 'Aleksandra Wasiewicz',
    en: 'Aleksandra Wasiewicz',
  },
  desc: {
    pl: `Łączymy wodę i powietrze w transporcie oraz sporcie! Poznaj naszą Wicemistrzynię Świata w regatach windsurfingowych.`,
    en: `We combine water and air with transport and sport! Meet our world runner-up in windsurfing regattas.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'wspieramy',
    'windsurfing',
    'ola wasiewicz',
    'aleksandra wasiewicz',
  ],
}

export const intro = {
  title: {
    pl: 'Aleksandra Wasiewicz',
    en: 'Aleksandra Wasiewicz',
  },
  desc: {
    pl: `Łączymy wodę oraz powietrze w transport i sport! Poznaj naszą Wicemistrzynię Świata w regatach windsurfingowych.`,
    en: `We combine water and air with transport and sport! Meet our world runner-up in windsurfing regattas.`,
  },
}

export const main = {
  title: {
    pl: 'Omida Sea And Air S.A.<br/> wspiera windsurfing!',
    en: 'Omida Sea And Air S.A.<br/> supports windsurfing!',
  },
  body: {
    pl: `
    <p>
    W 2021 roku wzięliśmy pod swoją opiekę utalentowaną <strong>Wicemistrzynię Aleksandrę Wasiewicz POL-122</strong>, która na swoim koncie posiada szereg osiągnięć sportowych w regatach windsurfingowych w Polsce i na świecie!
    </p>
    <p>
    Chcemy być częścią wydarzeń, mistrzostw oraz <strong>wsparciem dla młodych talentów</strong>, które z pasją i zamiłowaniem kochają te same żywioły co my. Kibicuj z nami i poznaj najbliższe terminy zawodów!
    </p>
    `,
    en: `
    <p>
    In 2021, we took care of the talented <strong>Vice-champion Aleksandra Wasiewicz POL-122</strong>, who has a number of sports achievements in windsurfing regattas in Poland and in the world!
    </p>
    <p>
    We want to be part of the events, championships and <strong>support for young talents</strong> who love the same elements with passion and love as we do. Cheer with us and learn about the next dates of the competition!
    </p>
    `,
  },
}

export const about = {
  title: {
    pl: 'Poznaj Wicemistrzynię<br/>Omida Sea And Air S.A.',
    en: 'Meet the runner-up of<br/>Omida Sea And Air S.A.',
  },
  desc: {
    pl: 'Aleksandra Wasiewicz od najmłodszych lat jest związana z wodą. Do jej głównych osiągnięć należy zdobycie srebra na Mistrzostwach Świata Formula Foil w Pucku, 2 miejsca na mistrzostwach Europy, 4 miejsca na Mistrzostwach Świata.',
    en: 'Aleksandra Wasiewicz has been associated with water from an early age. Her main achievements include winning silver at the Formula Foil World Championships in Puck, 2 places at the European Championships, 4 places at the World Championships.',
  },
  subtitle: {
    pl: 'Sea & Air Wspieramy',
    en: 'Sea & Air Supporting',
  },
  sections: [
    {
      title: {
        pl: 'Aleksandra Wasiewicz',
        en: 'Aleksandra Wasiewicz',
      },
      desc: {
        pl: '<em>"Windsurfing jest moją pasją, zaczęło się od tego, jak mama w wieku 8 lat zapisała mnie do Sopockiego Klubu Żeglarskiego, żebym spróbowała czegoś nowego. Windsurfing kocham za kontakt z naturą, za wolność, którą odczuwam na wodzie i rywalizację"</em>',
        en: '<em>"Windsurfing is my passion, it started when my mother, at the age of 8, enrolled me in the Sopot Sailing Club so that I would try something new. I love windsurfing for contact with nature, for the freedom I feel on the water and for competition"</em>',
      },
      social: {
        pl: 'Zerknij na social media Oli 😎',
        en: `Check out Ola's social media`,
      },
    },
  ],
}

export const blog = {
  title: {
    pl: 'Publikacje o Aleksandrze',
    en: 'Publications about Aleksandra',
  },
  desc: {
    pl: 'Poniżej znajdziesz najnowsze artykuły związane z naszą wicemistrzynią - śledź razem z nami sportowe osiągnięcia Oli.',
    en: "Below you will find the latest articles related to our runner-up - follow Ola's sports achievements with us.",
  },
}

export const achievements = {
  title: {
    pl: 'Najnowsze osiągnięcia!',
    en: 'Latest achievements!',
  },

  events: [
    {
      title: {
        pl: 'Hiszpania (2 rodzaje zawodów)',
        en: 'Spain (2 types of competition)',
      },
      desc: {
        pl: "4 miejsce w New Year's Race<br/>3 miejsce w kobietach juniorek w Andalusian Olympic Week",
        en: "4th place in New Year's Race <br/> 3rd place in female juniors in Andalusian Olympic Week",
      },
    },
    {
      title: {
        pl: 'Puchar Burmistrza Pucku',
        en: 'The Mayor of Puck Cup',
      },
      desc: {
        pl: '2 miejsce U20 kobiety',
        en: '2nd place U20 women',
      },
    },
    {
      title: {
        pl: 'Puchar Prezydenta Miasta Sopot',
        en: 'The President of Sopot Cup',
      },
      desc: {
        pl: '4 miejsce U20 kobiety',
        en: '4th place U20 women',
      },
    },
    {
      title: {
        pl: 'Mistrzostwa Świata Foil w Pucku 2021',
        en: 'Foil World Championship in Puck 2021',
      },
      desc: {
        pl: '2 miejsce Open kobiety<br/1 miejsce U17 kobiety',
        en: '2nd place Open women <br/1 place U17 women',
      },
    },
    {
      title: {
        pl: 'Regaty LOTOS Nord Cup',
        en: 'LOTOS Nord Cup',
      },
      desc: {
        pl: '1 miejsce U20 kobiety',
        en: '1st place U20 women',
      },
    },
    {
      title: {
        pl: 'Mistrzostwa Polski Foil w Pucku',
        en: 'Polish Foil Championships in Puck',
      },
      desc: {
        pl: '21-23.09.2021',
        en: '21-23.09.2021',
      },
    },
    {
      title: {
        pl: 'Chorwacja - Mistrzostwa Europy',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '8-12.09.2021<br/>10 miejsce kobiety U19',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'IQfoil International Games w Kadyksie',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '2 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'Puchar Polskiego Związku Żeglarskiego w Pucku',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: 'Eliminacje do World Sailing w Hadze - 1 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'Gdynia Sailing Days 2022',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '1 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'Mistrzostwa Polski IQfoil 2022',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '1 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'IQfoil Games Kadyks 2023',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '1 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'IQfoil u19 Regaty o Puchar Polskiego Związku Żeglarskiego w Krynicy Morskiej',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '1 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'Puchar Prezydenta miasta Sopotu 2023',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '1 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'Formula windsurfing foil World Championship we Włoszech',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '3 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'Regaty o Puchar Gdańska',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '1 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    },
    {
      title: {
        pl: 'Gdynia Sailing Days 2023 IQfoil u19',
        en: 'Croatia - European Championship',
      },
      desc: {
        pl: '1 miejsce',
        en: '8-12.09.2021<br/>10th place women U19',
      },
    }
  ],
}

export const upcoming = {
  title: {
    pl: 'Najbliższe zawody',
    en: 'Upcoming tournaments',
  },
  events: [
    {
      title: {
        pl: '29.01.2024',
        en: '29.01.2024',
      },
      desc: {
        pl: 'Mistrzostwa Świata w Lanzarote',
        en: 'World Championships Lanzarote',
      },
    },
    {
      title: {
        pl: '16.05.2024',
        en: '16.05.2024',
      },
      desc: {
        pl: 'Puchar Polskiego Związku Żeglarskiego',
        en: 'Polish Yachting Association Cup',
      },
    },
    {
      title: {
        pl: '06.06.2024',
        en: '06.06.2024',
      },
      desc: {
        pl: 'Puchar Prezydenta Sopotu',
        en: 'The President of Sopot Cup',
      },
    },
    {
      title: {
        pl: '19.08.2024',
        en: '19.08.2024',
      },
      desc: {
        pl: 'Mistrzostwa Świata w Silvaplana U23',
        en: 'World Championships U23 Silvaplana',
      },
    },
    {
      title: {
        pl: '20.09.2024',
        en: '20.09.2024',
      },
      desc: {
        pl: 'Mistrzostwa Polski IQFoil Seniorów ',
        en: 'IQfoil Polish Championships',
      },
    },
  ],
}
